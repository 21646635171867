import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import SbEditable from 'storyblok-react'
 
import Layout from "../components/layout"
import DynamicComponent from "../components/dynamicComponent"
import useStoryblok from "../lib/storyblok"
 
const NotFoundPage = ({ location }) => { 
  let components = null
  let story = useStoryblok(null, location)
 
  if(story) {
    components = story.content.body.map(blok => {
      return (<DynamicComponent blok={blok} key={blok._uid} />)
    })
  }
 
  return (
  <Layout>
    
    <SbEditable content={story ? story.content : false }>
    <h1 className="text-center m-8">{ story ? story.content.title : 'Sorry, Page Not Found!' }</h1>
    { components }
    <p className="text-center m-8">
      <Link to="/home">Go back to the Homepage</Link> <br />
    </p>
    <StaticImage
      src="../images/cotton.jpg"
      quality={95}
      formats={["AUTO", "WEBP", "AVIF"]}
      alt="Yarns"
      style={{ marginBottom: `1.45rem` }}
    />
    </SbEditable>
  </Layout>
)}
 
export default NotFoundPage